import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import OtherCaseStudy from "../../components/other-case"

import detail1 from "../../assets/img/yolcu-detail1.png"
import detail2 from "../../assets/img/yolcu-detail2.png"

import eelbeyglu from "../../assets/img/teams/11.jpg"
import serife from "../../assets/img/teams/20.jpg"





const ReferancesDetail = () => (
    <Layout>
        <SEO title="Yolcu360 Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Yolcu 360</h1>
                <p>Size “araç kiralamak ne kolaymış” dedirtmek için yıllar önce yola çıkan Türkiyenin en büyük online araç kiralama sitesi Yolcu 360, 2018 yılında Google ve Facebook Ads alanında performans pazarlama danışmanlığı alanında Flatart ile çalışmaya başladı.</p>
                <p>Süreç içerisinde dönüşüm oranını artırmak için Google Analytics üzerinden edinilen trafiğin dönüşüme ulaşmasını engelleyen unsurlar tespit edilerek daha iyi dönüşüm oranına sahip olmak için geliştirmeler yapıldı.</p>
                <p>Yapılan çalışmalar neticesinde 6 ay gibi kısa bir sürede dönüşüm oranında %24 artış sağlanırken PPC maliyetlerinde %12 oranında düşüş yaşandı.</p>
                <h2>Zorluklar</h2>
                <ul>
                    <li>&bull; Yolcu360’ın bir araç kiralama markası olmadığının, aslında tüm araç kiralama şirketlerinin buluşma noktası olduğunun anlatılması,</li>
                    <li>&bull; Yolcu360’ın uçak bileti satın alma, otel rezervasyonu gibi yolculuk ile çağrışım yapan diğer hizmetleri kapsamadığına dair iletişimin yapılması,</li>
                    <li>&bull; Düşük dönüşüm oranları,</li>
                    <li>&bull; Flatart ve Yolcu360’ın iş birliği döneminde, markanın müşteri zihninde sadece uzun dönem kiralama hizmeti verdiği yönünde algıya sahip olması.</li>
                </ul>

            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>+%24</span>
                        <p>Dönüşüm oranı<br />
                            artışı.</p>
                    </div>
                    <div className="box">
                        <span>-%12</span>
                        <p>Tıklama başı maliyet<br />
                            azalması.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                        <div className="box">
                            <Link to="/sosyal-medya/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Dijital Pazarlama Stratejist:</li>
                            <li>Google & Facebook Ads:</li>
                        </ul>
                        <ul>
                            <li><img src={eelbeyglu}  alt="Alaattin Emre Elbeyoğlu"/>Emre Elbeyoğlu</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">

            <div className="customer-content pt60">
                <h2>Dönüşüm oranını artıran popup'lar.
Etkili bir iletişim.</h2>
                <img style={{ width: '50%', margin: '20px auto' }} src={detail1} />

                <p>Geleneksel bir iletişim aracı olmasa da kullanıcıları rahatsız etmeyecek biçimde tasarlanan popup’lar etkili bir iletişim için olmazsa olmazdır. Yolcu360 için yürüttüğümüz çalışmalarda da Popupsmart servis sağlayıcısı sayesinde hayata geçirilen popup’lar aracılığıyla e-posta bilgileri toplandı ve hedef kitlenin bilgilendirilmesi için yine popup’lar kullanıldı.</p>
                <img className="gads-imgx" src={detail2} alt="Google ads scripts ile entegre reklam stratejileri" />
                <h2>“Automation is everything”<br />
                    Google Ads Scripts ile entegre<br />
                    reklam stratejileri.</h2>
                <p>Yolcu360 üzerinde yönetilen Google Ads kampanyaları yüksek bütçeler gerektirdiği için Google Ads Scripts’lerin yardımıyla süreçler otomasyona aldındı. Böylece araç durumu, fiyat aralıkları gibi değişken metriklere script’ler hazırlanarak optimizasyon çalışmaları sağlanmış oldu.</p>

            </div>

        </div>



        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
